import React, { Component } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import { library } from '@fortawesome/fontawesome-svg-core'
//eslint-disable-next-line 
import { faAnglesDown, faCheck, faChevronRight, faMugHot, faXmark, fas } from '@fortawesome/free-solid-svg-icons'
//eslint-disable-next-line 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//eslint-disable-next-line 
import { faTwitter, faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import Layout from './components/Layout';

import '../css/Rules.css'

library.add(fas, faTwitter, faFontAwesome)

// Our rules are designed to create a fair and supportive environment where everyone can focus on working together to achieve the team's goals. For example, our rules prohibit name-calling, harassment, and other forms of disruptive behavior.<br/><br/>
// Finally, our rules are designed to maximize our chances of success. They require everyone to follow the raid leader's instructions and play their role to the best of their ability. 
export default class RaidRules extends Component {
    render() {
        return (
            <Layout>
                <h1>OGNITL Raid Rules</h1>
                <div className='rulesIntro bottomBorder' >
                    <p>
                    Raiding is one of the most challenging and rewarding aspects of World of Warcraft, and it's important to have a set of rules in place to ensure that everyone has a fun and productive experience.
                    These rules are not meant to be punitive or restrictive. Instead, they are designed to create a fair and supportive environment where everyone can contribute to the team's success.
                    </p>
                    <br/>
                    <p>
                        Thank you for taking the time to read these rules. If you have any further questions you can always contact us for support on discord.
                    </p>
                    <p className="rulesSignature">
                        - OGNITL GM & Officer Team
                    </p>
                </div>
                <div className='raidRequirements'>
                    <h2>What do we expect from you?</h2>
                    <h4>Heroic Raiding</h4>
                    <ul className="fa-ul requirementsList">
                        <li>
                            <span className="fa-li"><FontAwesomeIcon icon={faCheck} style={{color: "#2DBAED", }} /></span>
                            <span>1900 M+ Rating (KSM)</span>
                        </li>
                        <li>
                            <span className="fa-li"><FontAwesomeIcon icon={faCheck} style={{color: "#2DBAED", }} /></span>
                            <span>Item level 465</span>
                            <span className="rightFloat"><i> (Gnarlroot -&gt; Smolderon)</i></span>
                        </li>
                        <li>
                            <span className="fa-li"><FontAwesomeIcon icon={faCheck} style={{color: "#2DBAED", }} /></span>
                            <span>Item level 470</span>
                            <span className="rightFloat"><i> (Tindral -&gt; Fyrakk)</i></span>
                        </li>
                    </ul>

                    <h4>Mythic Raiding</h4>
                    <ul className="fa-ul requirementsList">
                        <li>
                            <span className="fa-li"><FontAwesomeIcon icon={faCheck} style={{color: "#2DBAED", }} /></span>
                            <span>2600 M+ Rating</span>
                        </li>
                        <li>
                            <span className="fa-li"><FontAwesomeIcon icon={faCheck} style={{color: "#2DBAED", }} /></span>
                            <span>Item level 482</span>
                        </li>
                        <li>
                            <span className="fa-li"><FontAwesomeIcon icon={faCheck} style={{color: "#2DBAED", }} /></span>
                            <span>7/9 HC Bosses <b>OR</b> Guild Prog</span>
                            <span className="rightFloat"><i>(Gnarlroot -&gt; Smolderon)</i></span>
                        </li>
                    </ul>
                </div>

                <div className='raidRequirements'>
                    <h2>What can you expect from us?</h2>
                    <h4>In ALL Raids</h4>
                    <ul className="fa-ul requirementsList">
                        <li>
                            <span className="fa-li"><FontAwesomeIcon icon={faCheck} style={{color: "#2DBAED", }} /></span>
                            <span>Feasts for every pull</span>
                        </li>
                        <li>
                            <span className="fa-li"><FontAwesomeIcon icon={faCheck} style={{color: "#2DBAED", }} /></span>
                            <span>Repair hammers on your request</span>
                        </li>
                        <li>
                            <span className="fa-li"><FontAwesomeIcon icon={faCheck} style={{color: "#2DBAED", }} /></span>
                            <span>Open minded raid leadership</span>
                        </li>
                        <li>
                            <span className="fa-li"><FontAwesomeIcon icon={faCheck} style={{color: "#2DBAED", }} /></span>
                            <span>Experienced and prepared Raid Leading</span>
                        </li>
                        <li>
                            <span className="fa-li"><FontAwesomeIcon icon={faCheck} style={{color: "#2DBAED", }} /></span>
                            <span>Guidance and support with your spec</span>
                        </li>
                        <li>
                            <span className="fa-li"><FontAwesomeIcon icon={faCheck} style={{color: "#2DBAED", }} /></span>
                            <span>Reliable Signups</span>
                        </li>
                    </ul>
                </div>

                <div className="accordionRoot ">
                    <h2>Frequently Asked Questions</h2>
                    
                    <div className="accordionContainer">
                    <Accordion
                        style={{
                            backgroundColor: 'rgba(255, 255, 255, 0.05)',
                            color: 'rgba(255, 255, 255, 1)',
                        }}>
                        <AccordionSummary
                            expandIcon={<FontAwesomeIcon icon={faAnglesDown} />}
                            aria-controls="panel3a-content"
                            id="panel3a-header">
                            <Typography className="accordionTitle"><h3>Why do we have these rules?</h3></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className="accordionSection">
                                    We have raiding rules to ensure that our raiding environment is a comfortable place, and so that everyone that participates is prepared and ready for action!
                                    Preparation is essential for successful raiding. <br/><br/>
                                    Our rules help to ensure that everyone is prepared for raids by requiring them to have a certain level of gear and knowledge. 
                                    This helps to ensure that everyone is able to contribute to the team and that we are not wasting time wiping due to people being undergeared or not knowing the mechanics of the fights.<br/><br/>
                                    Our rules also help to minimize conflict and drama. Raiding can be a stressful experience, especially during difficult encounters. <br/>
                                    This helps us to coordinate our attacks and defenses effectively and to avoid making costly mistakes.
                                    By following our raiding rules, we can create a positive and productive environment where everyone can contribute to the team's success and have fun.<br/><br/>
                                    Raiding can also be a stressful experience, especially during difficult encounters. Our rules are designed to minimize conflict and drama by setting clear expectations for the behavior we ask from all of our raiders.
                                    <br/><br/>
                                    We believe that everyone has something to offer, and we encourage everyone to participate in raids and provide feedback. We are always looking for ways to improve, and we want to create a guild where everyone feels comfortable and valued.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    </div>
                    <div className="accordionContainer">
                    <Accordion
                    style={{
                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        color: 'rgba(255, 255, 255, 1)',
                    }}>
                        <AccordionSummary
                            expandIcon={<FontAwesomeIcon icon={faAnglesDown} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <Typography className="accordionTitle"><h3>What are valid reasons for not being able to sign up or out of a raid?</h3></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className="accordionSection">
                                <p>
                                    It's important to remember that we're all human beings, and we all have lives outside of the game. Sometimes things happen that are beyond our control, and we may need to miss a raid as a result. This is perfectly okay.
                                    Raiding is a team activity, and the most important thing is that we all support each other. If you need to miss a raid due to something like an illness, a family emergency, or a work commitment, please don't feel guilty. The rest of the team will understand.
                                    Of course, it's always best to give your raid leader as much notice as possible if you need to miss a raid. This will give them time to make adjustments to the raid composition and strategy. But if something unexpected comes up, don't worry. Just let your raid leader know as soon as possible, and they will be understanding.
                                    Remember, the most important thing is that we all have fun and enjoy the game. If you need to miss a raid, don't let it ruin your experience. Just let your raid leader know, and come back next time.
                                </p>
                                <p>We all know that life happens and sometimes you might not be able to sign out of a raid in time. We totally understand, and we want to be as supportive as possible. Here are some examples of reasons that we would consider valid:</p>
                                <ul className="fa-ul">
                                    <li>
                                        <span className="fa-li"><FontAwesomeIcon icon={faCheck} style={{color: "#2DBAED", }} /></span>You are struggling wtih your mental health.
                                    </li>
                                    <li>
                                        <span className="fa-li"><FontAwesomeIcon icon={faCheck} style={{color: "#2DBAED", }} /></span>You or a loved one is sick or injured
                                    </li>
                                    <li>
                                        <span className="fa-li"><FontAwesomeIcon icon={faCheck} style={{color: "#2DBAED", }} /></span>You have a family emergency
                                    </li>
                                    <li>
                                        <span className="fa-li"><FontAwesomeIcon icon={faCheck} style={{color: "#2DBAED", }} /></span>You have technical issues, such as a power outage or internet interruption
                                    </li>
                                    <li>
                                        <span className="fa-li"><FontAwesomeIcon icon={faCheck} style={{color: "#2DBAED", }} /></span>Something else unexpected comes up.
                                    </li>
                                    <li>
                                        <span className="fa-li"><FontAwesomeIcon icon={faCheck} style={{color: "#2DBAED", }} /></span>Dog eating your PC
                                    </li>
                                </ul>
                                <p>Here are also reasons that we would not consider valid for being late or missing the raid entirely.</p>
                                <ul className="fa-ul">
                                    <li>
                                        <span className="fa-li"><FontAwesomeIcon icon={faXmark} style={{color: "#c24747", }} /></span>Forgot the time, or forgot the raid.
                                    </li>
                                    <li>
                                        <span className="fa-li"><FontAwesomeIcon icon={faXmark} style={{color: "#c24747", }} /></span>Didn't feel like raiding tonight.
                                    </li>
                                    <li>
                                        <span className="fa-li"><FontAwesomeIcon icon={faXmark} style={{color: "#c24747", }} /></span>I made other plans last minute.
                                    </li>
                                </ul>
                                <p>If you need to miss a raid or sign out late, please let your raid leader know as soon as possible. We want to make sure that everyone has a good time and that the raid is a success, but we also understand that life happens.</p><br/>
                                
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    </div>
                    <div className="accordionContainer">
                    <Accordion
                        style={{
                            backgroundColor: 'rgba(255, 255, 255, 0.05)',
                            color: 'rgba(255, 255, 255, 1)',
                        }}>
                        <AccordionSummary
                            expandIcon={<FontAwesomeIcon icon={faAnglesDown} />}
                            aria-controls="panel2a-content"
                            id="panel2a-header">
                            <Typography className="accordionTitle"><h3>Who decides whether a reason is valid or invalid?</h3></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className="accordionSection">
                                <p>
                                    We understand that sometimes things come up that make it difficult or impossible to show up for a raid. We want to be as understanding as possible, so we have developed a list of guidelines to help us determine whether a reason for not showing up is valid or invalid.
                                    When considering whether a reason is valid, we take into account the following factors:
                                </p>
                                <ul className="fa-ul">
                                    <li>
                                        <span className="fa-li"><FontAwesomeIcon icon={faChevronRight} style={{color: "#2DBAED", }} /></span>Is the reason beyond your control? We understand that there are some things that are simply out of your control, such as illness, family emergencies, and unexpected work commitments. These are all valid reasons for not showing up.
                                    </li>
                                    <li>
                                        <span className="fa-li"><FontAwesomeIcon icon={faChevronRight} style={{color: "#2DBAED", }} /></span>Is the reason consistent? If you have a history of not showing up for raids without a valid reason, we may be less likely to consider your reason valid in the future.
                                    </li>
                                    <li>
                                        <span className="fa-li"><FontAwesomeIcon icon={faChevronRight} style={{color: "#2DBAED", }} /></span>Is it reasonable? Some reasons for not showing up may be understandable, but not necessarily reasonable. For example, if you simply forgot about the raid, or if you didn't feel like coming, these are not valid reasons for not showing up.
                                    </li>
                                </ul>
                                <p>
                                    We also want to be fair and consistent in our decision-making, so we always consider the individual circumstances of each case. We understand that everyone's life is different, and we don't want to punish anyone for something that was out of their control.
                                    If you have any questions about whether a reason for not showing up is valid, please don't hesitate to contact your raid leader. We are always happy to talk to you and help you understand our guidelines.
                                </p>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    </div>
                    <div className="accordionContainer">
                    <Accordion className='accordionContainer'
                        style={{
                            backgroundColor: 'rgba(255, 255, 255, 0.05)',
                            color: 'rgba(255, 255, 255, 1)',
                        }}>
                        <AccordionSummary
                            expandIcon={<FontAwesomeIcon icon={faAnglesDown} />}
                            aria-controls="panel2a-content"
                            id="panel2a-header">
                            <Typography className="accordionTitle"><h3>What should I keep in mind when I'm signing up to a raid?</h3></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className="accordionSection">
                                <p>
                                    When signing up for a raid, it is important to be prepared and respectful of the other raiders' time. This means being on time, having the necessary gear and consumables, and being familiar with the raid mechanics and strategy. It is also important to be able to communicate effectively with the raid leader and other raiders.
                                </p>
                                <ul className="fa-ul">
                                    <li>
                                        <span className="fa-li"><FontAwesomeIcon icon={faChevronRight} style={{color: "#2DBAED", }} /></span>Sign up as early as possible. This will give the raid leader time to plan the raid composition and strategy.
                                    </li>
                                    <li>
                                        <span className="fa-li"><FontAwesomeIcon icon={faChevronRight} style={{color: "#2DBAED", }} /></span>Be realistic about your availability. If you are not sure if you will be able to make the raid, it is better not to sign up.
                                    </li>
                                    <li>
                                        <span className="fa-li"><FontAwesomeIcon icon={faChevronRight} style={{color: "#2DBAED", }} /></span>Make sure you have the necessary gear and consumables. This includes food, potions, runes, and any other items that you may need.
                                    </li>
                                    <li>
                                        <span className="fa-li"><FontAwesomeIcon icon={faChevronRight} style={{color: "#2DBAED", }} /></span>Be prepared to communicate with the raid leader and other raiders. This will help to ensure that everyone is on the same page and that the raid runs smoothly.
                                    </li>
                                    <li>
                                        <span className="fa-li"><FontAwesomeIcon icon={faChevronRight} style={{color: "#2DBAED", }} /></span>Be respectful of other raiders' time. This means being on time and being ready to raid when the raid leader says so.
                                    </li>
                                    <li>
                                        <span className="fa-li"><FontAwesomeIcon icon={faChevronRight} style={{color: "#2DBAED", }} /></span>Read the raid guide. This will give you a good understanding of the raid mechanics and strategy.
                                    </li>
                                    <li>
                                        <span className="fa-li"><FontAwesomeIcon icon={faChevronRight} style={{color: "#2DBAED", }} /></span>Watch raid videos. This can help you to visualize the raid mechanics and see how the raid is typically run.
                                    </li>
                                    <li>
                                        <span className="fa-li"><FontAwesomeIcon icon={faChevronRight} style={{color: "#2DBAED", }} /></span>Practice your raid rotation. This will help you to perform well in the raid and contribute to the team's success.
                                    </li>
                                    <li>
                                        <span className="fa-li"><FontAwesomeIcon icon={faChevronRight} style={{color: "#2DBAED", }} /></span>Ask for help if you need it. Don't be afraid to ask your raid leader or other raiders for help if you are struggling with something. Even before the raid starts.
                                    </li>
                                    <li>
                                        <span className="fa-li"><FontAwesomeIcon icon={faChevronRight} style={{color: "#2DBAED", }} /></span>Be respectful of the raid leader's authority. The raid leader is responsible for making decisions that are in the best interests of the raid. If you disagree with a decision, you can always discuss it with the raid leader after the raid.
                                    </li>
                                </ul>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    </div>
                </div>
            </Layout>
        )
    }
}