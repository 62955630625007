import Layout from "./components/Layout";
import '../css/AboutUs.css'
import curves1 from '../img/curve-s1.png'
import curves2 from '../img/curve-s2.png'
import curves3 from '../img/curve-s3.png'
import datheaSumm from '../img/dathea_summoning_ritual.png'
import allbears from '../img/fullbearrun.png'
import s1mount from '../img/ognitl_s1_achievrun.jpg'
import s3mount from '../img/ognitl_s3_achievrun.png'

import t1_1 from    '../img/scav_hunt_202312/t1-1.png'
import t1_2 from    '../img/scav_hunt_202312/t1-2.png'
import t2 from      '../img/scav_hunt_202312/t2.png'
import t3 from      '../img/scav_hunt_202312/t3.png'
import t4 from      '../img/scav_hunt_202312/t4.png'
import t5 from      '../img/scav_hunt_202312/t5.png'
import t6 from      '../img/scav_hunt_202312/t6.png'
import t7_1 from    '../img/scav_hunt_202312/t7-1.png'
import t7_2 from    '../img/scav_hunt_202312/t7-2.png'
import t8 from      '../img/scav_hunt_202312/t8.png'
import t9_1 from    '../img/scav_hunt_202312/t9-1.png'
import t9_2 from    '../img/scav_hunt_202312/t9-2.png'
import t9_3 from    '../img/scav_hunt_202312/t9-3.png'
import t9_4 from    '../img/scav_hunt_202312/t9-4.png'
import t10_1 from   '../img/scav_hunt_202312/t10-1.png'
import t10_2 from   '../img/scav_hunt_202312/t10-2.png'
import t10_3 from   '../img/scav_hunt_202312/t10-3.png'
import t11 from     '../img/scav_hunt_202312/t11.png'
import t12 from     '../img/scav_hunt_202312/t12.png'
import t13 from     '../img/scav_hunt_202312/t13.png'
import t14 from     '../img/scav_hunt_202312/t14.png'
import t15 from     '../img/scav_hunt_202312/t15.png'
import t16 from     '../img/scav_hunt_202312/t16.png'
import t17 from     '../img/scav_hunt_202312/t17.png'
import t18 from     '../img/scav_hunt_202312/t18.png'
import t19 from     '../img/scav_hunt_202312/t19.png'
import t20 from     '../img/scav_hunt_202312/t20.png'

import { Paper, Typography, Button } from '@mui/material';
import Carousel from 'react-material-ui-carousel';

function AboutUs(){
    return(
        <Layout>
            <h1>ABOUT US</h1>
            <h2 className="center">Guild Raid Adventures</h2>
            <Carousel
                autoPlay={false}
                height={"61vh"}
                sx={{}}
                className="aboutCarousel"
            >
                <Paper>
                    <pTitle>Dragonflight AOTC season 1</pTitle>
                    <img src={curves1}/>
                </Paper>
                <Paper>
                    <pTitle>Dragonflight AOTC season 2</pTitle>
                    <img src={curves2}/>
                </Paper>
                <Paper>
                    <pTitle>Dragonflight AOTC season 3</pTitle>
                    <img src={curves3}/>
                </Paper>
                <Paper>
                    <pTitle>Dathea summoning ritual</pTitle>
                    <img src={datheaSumm}/>
                </Paper>
                <Paper>
                    <pTitle>Bear raid</pTitle>
                    <img src={allbears}/>
                </Paper>
                <Paper>
                    <pTitle>S1 achievement run mount</pTitle>
                    <img src={s1mount}/>
                </Paper>
                <Paper>
                    <pTitle>S3 achievement run mount</pTitle>
                    <img src={s3mount}/>
                </Paper>
            </Carousel>
            <br/><br/>
                <h2 className="center">Scavenger Hunt December 2023</h2>
            <Carousel
                autoPlay={true}
                height={"61vh"}
                sx={{}}
                className="aboutCarousel">
                <Paper>
                    <pTitle>Task 1 (1) - A New Journey [FIND]</pTitle>
                    <pSubtitle>Team 'Pobratimi'</pSubtitle>
                    <img src={t1_1}/>
                </Paper>
                <Paper>
                    <pTitle>Task 1 (2) - A New Journey [FIND]</pTitle>
                    <pSubtitle>Team 'Sponge & Bob TM'</pSubtitle>
                    <img src={t1_2}/>
                </Paper>
                <Paper>
                    <pTitle>Task 2 - Dragonflight [FIND]</pTitle>
                    <pSubtitle>Team 'Sponge & Bob TM'</pSubtitle>
                    <img src={t2}/>
                </Paper>
                <Paper>
                    <pTitle>Task 3 - Friendship [COMPLETION]</pTitle>
                    <img src={t3}/>
                </Paper>
                <Paper>
                    <pTitle>Task 4 - Stay Hydrated Fellas [COUNT]</pTitle>
                    <pSubtitle>Teams 'Sponge & Bob TM', 'Scandic Degenerates', 'The Weighted Blankets' and 'Amogus'</pSubtitle>
                    <img src={t4}/>
                </Paper>
                <Paper>
                    <pTitle>Task 5 - Lava Ledge [FIND]</pTitle>
                    <pSubtitle>Team 'The Weighted Blankets'</pSubtitle>
                    <img src={t5}/>
                </Paper>
                <Paper>
                    <pTitle>Task 6 - Ottuk Organiser [COUNT]</pTitle>
                    <pSubtitle>Team 'The Weighted Blankets'</pSubtitle>
                    <img src={t6}/>
                </Paper>
                <Paper>
                    <pTitle>Task 7 (1) - Carved in Stone [FIND]</pTitle>
                    <pSubtitle>Team 'Sponge & Bob TM'</pSubtitle>
                    <img src={t7_1}/>
                </Paper>
                <Paper>
                    <pTitle>Task 7 (2) - Carved in Stone [FIND]</pTitle>
                    <pSubtitle>Team 'Amogus'</pSubtitle>
                    <img src={t7_2}/>
                </Paper>
                <Paper>
                    <pTitle>Task 8 - Avaker [FIND]</pTitle>
                    <pSubtitle>Team 'Amogus'</pSubtitle>
                    <img src={t8}/>
                </Paper>
                <Paper>
                    <pTitle>Task 9 (1) - Leap of Faith?? [COMPLETION]</pTitle>
                    <img src={t9_1}/>
                </Paper>
                <Paper>
                    <pTitle>Task 9 (2) - Leap of Faith?? [COMPLETION]</pTitle>
                    <img src={t9_2}/>
                </Paper>
                <Paper>
                    <pTitle>Task 9 (3) - Leap of Faith?? [COMPLETION]</pTitle>
                    <img src={t9_3}/>
                </Paper>
                <Paper>
                    <pTitle>Task 9 (4) - Leap of Faith?? [COMPLETION]</pTitle>
                    <img src={t9_4}/>
                </Paper>
                <Paper>
                    <pTitle>Task 10 (1)- Tounge Twister [COUNT]</pTitle>
                    <pSubtitle>Team 'Pobratimi'</pSubtitle>
                    <img src={t10_1}/>
                </Paper>
                <Paper>
                    <pTitle>Task 10 (2) - Tounge Twister [COUNT]</pTitle>
                    <pSubtitle>Team 'Streichholzschächtelchen'</pSubtitle>
                    <img src={t10_2}/>
                </Paper>
                <Paper>
                    <pTitle>Task 10 (3) - Tounge Twister [COUNT]</pTitle>
                    <pSubtitle>'Team NoJokingMatter'</pSubtitle>
                    <img src={t10_3}/>
                </Paper>
                <Paper>
                    <pTitle>Task 11 - New Beauty Queen [FIND]</pTitle>
                    <pSubtitle>Team 'The Weighted Blankets'</pSubtitle>
                    <img src={t11}/>
                </Paper>
                <Paper>
                    <pTitle>Task 12 - Bubble Ballet [FIND]</pTitle>
                    <pSubtitle>Team 'The Church of Falk'</pSubtitle>
                    <img src={t12}/>
                </Paper>
                <Paper>
                    <pTitle>Task 13 - Little Leagues [COUNT]</pTitle>
                    <pSubtitle>Team 'Alone Together'</pSubtitle>
                    <img src={t13}/>
                </Paper>
                <Paper>
                    <pTitle>Task 14 - Skyline [FIND]</pTitle>
                    <pSubtitle>Team 'Amogus'</pSubtitle>
                    <img src={t14}/>
                </Paper>
                <Paper>
                    <pTitle>Task 15 - Peculiar Unicorn [FIND]</pTitle>
                    <pSubtitle>Team 'Alone Together'</pSubtitle>
                    <img src={t15}/>
                </Paper>
                <Paper>
                    <pTitle>Task 16 - Barely Beatable [COUNT]</pTitle>
                    <pSubtitle>Team 'The Weighted Blankets'</pSubtitle>
                    <img src={t16}/>
                </Paper>
                <Paper>
                    <pTitle>Task 17 - Wood [FIND]</pTitle>
                    <pSubtitle>Team 'Sponge & Bob TM'</pSubtitle>
                    <img src={t17}/>
                </Paper>
                <Paper>
                    <pTitle>Task 18 - Greatest of all time [FIND]</pTitle>
                    <pSubtitle>Team 'Amogus'</pSubtitle>
                    <img src={t18}/>
                </Paper>
                <Paper>
                    <pTitle>Task 19 - Armed to the Teeth [COUNT]</pTitle>
                    <pSubtitle>Team 'Alone Together'</pSubtitle>
                    <img src={t19}/>
                </Paper>
                <Paper>
                    <pTitle>Task 20 - Top of the Food Chain [COMPLETION]</pTitle>
                    <pSubtitle>Team 'Sponge & Bob TM'</pSubtitle>
                    <img src={t20}/>
                </Paper>
            </Carousel>
        </Layout>
    );
}
export default AboutUs;