import Layout from './components/Layout';
import '../css/Home.css';

function Home() {
    return (
      <Layout>
          <h1>Welcome To OGNITL!</h1>
          <iframe className="progBar" src="https://raider.io/widgets/boss-progress?raid=latest&name_style=logo&difficulty=latest&region=eu&realm=sylvanas&guild=Our+Guild+Name+Is+Too+Lo&boss=latest&period=until_kill&orientation=horizontal&hide=&chromargb=transparent&theme=dragonflight" 
          frameborder="0" height="144px" width="1280px"/>
          <div className="column left">
          <p>
            Welcome to the very best guild on the EU realm Sylvanas, or atleast the most fun one.
            <br/><br/>
            Our Guild Name Is Too Lo (OGNITL) was created on December 5th 2022. After we decided that we did not want to go looking for a guild that would suit the social aspect we were seeking, we decided to make our own guild with a goal to be social, comforting, and also progressive in raiding.
            What we intended to keep as a relatively small guild that we would share with our closest friends and attempt small raid runs with, turned into something very large quite quickly.
            <br/><br/>
            Thank you for being with us. We look forward to playing WoW (and other games too!) with you on regular basis.

          </p>
          <br/><br/>
          {/* <iframe width="90%" height="450" margin="auto" src="https://www.youtube.com/embed/live_stream?channel=UCGf9VOaOuPIkUhVEv2zL91A" frameborder="0" allowfullscreen></iframe> */}
          <iframe src="https://player.twitch.tv/?channel=ognitl&parent=ognitl.com" frameborder="0" allowfullscreen="true" scrolling="no" height="450" width="100%"/>
          </div>
          <div className="column right">
          <iframe className="discordEmbed" src="https://discord.com/widget?id=1051658709060816947&theme=dark" width="420" height="700" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"/>
          </div>

      </Layout>
    );
  }
  
  export default Home;