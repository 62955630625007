import Layout from '../components/Layout';
import React, { useState, useEffect} from 'react';
import { TextEncoder, TextDecoder } from 'text-encoding';
import '../../css/Home.css';
import axios from 'axios'

global.TextEncoder = TextEncoder;
global.TextDecoder = TextDecoder;

export default function TGI2024(props) {
    const [csvData, setCsvData] = useState([]);

    useEffect(() => {
        fetchCSVData();
    }, []);

    const fetchCSVData = () => {
        const csvUrl = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vThi9LBctNurW1MRjvdkHF8PfblbaBnCCm_Vb6dirUivxT8C7GAmccCrvauBDpELgZH-8RDfUDOSEq8/pub?gid=1847572517&single=true&output=csv'; // Replace with your Google Sheets CSV file URL
        axios.get(csvUrl)
            .then((response) => {
                const parsedCsvData = parseCSV(response.data);
                setCsvData(parsedCsvData);
                console.log(parsedCsvData);
            })
            .catch((error) => {
                console.error('Error fetching CSV data:', error);
            });
    }

    function parseCSV(csvText) {
        const rows = csvText.split(/\r?\n/); // Split CSV text into rows, handling '\r' characters
        const headers = rows[0].split(','); // Extract headers (assumes the first row is the header row)
        const data = []; // Initialize an array to store parsed data
        for (let i = 1; i < rows.length; i++) {
            const rowData = rows[i].split(','); // Split the row, handling '\r' characters
            const rowObject = {};
            for (let j = 0; j < headers.length; j++) {
                rowObject[headers[j]] = rowData[j];
            }
            data.push(rowObject);
        }
        return data;
    }

    function getValue() {
        var table = document.getElementById("tgp_table");
        var cells = table.getElementsByTagName("td");
        for (var i = 0; i < cells.length; i++) {
        var cellValue = cells[i].innerHTML; // or cells[i].textContent.
        console.log(cellValue);
        }
    }

    function getTGPStyle(data)
    {
        if(data < 5 && data != '')
        {
            return 'TGPrarity0'
        }
        else if(data >= 5 && data <= 8 )
        {
            return 'TGPrarity1'
        }
        else if(data >= 9 && data <= 14 )
        {
            return 'TGPrarity2'
        }
        else if(data >= 15 && data <= 18 )
        {
            return 'TGPrarity3'
        }
        else if(data >= 19)
        {
            return 'TGPrarity4'
        }

    }
    let y;
    let score = [];

    function toSomething(i)
    {
        y = i;

        score.push(y[1]);
        score = score.filter((value, index) => score.indexOf(value) === index);
        score.sort(function(a, b)
                {
                    return b - a;
                });
        
    }

    function getTopScore()
    {
        return score[0];
    }

    function getRankStyle(z)
    {
        if(z == score[0])
        { return 'tgp_gold'}
        else if(z == score[1])
        { return 'tgp_silver'}
        else if(z == score[2])
        { return 'tgp_bronze'}
    }

    function getCompletionStyle(z)
    {
        if(z >= 16 && z <= 29)
        { return 'tgp_completion_1'}
        else if(z >= 30 && z <= 49)
        { return 'tgp_completion_2'}
        else if(z >= 50 && z <= 74)
        { return 'tgp_completion_3'}
        else if(z >= 75 && z <= 89)
        { return 'tgp_completion_4'}
        else if(z >= 90 && z <= 119)
        { return 'tgp_completion_5'}
        else if(z >= 120 && z <= 149)
        { return 'tgp_completion_6'}
        else if(z >= 150 )
        { return 'tgp_completion_7'}
    }

    function getChallengeStlye(z)
    {
        if(z.startsWith('1'))
        { return 'tgp_completion_1'}
        else if(z.startsWith('2'))
        { return 'tgp_completion_2'}
        else if(z.startsWith('3'))
        { return 'tgp_completion_3'}
        else if(z.startsWith('4'))
        { return 'tgp_completion_4'}
        else if(z.startsWith('5'))
        { return 'tgp_completion_5'}
        else if(z.startsWith('6'))
        { return 'tgp_completion_6'}
        else if(z.startsWith('7'))
        { return 'tgp_completion_7'}
    
    }
    
    function doWork(data, index)
    {
        if(index == 1)
        { return getRankStyle(data); }
        else if(index == 2)
        { return getCompletionStyle(data); }
        else if (index >= 3 && index <= 10)
        { return getTGPStyle(data); }
        else if (index >= 11)
        { return getChallengeStlye(data); }
    }

    return (
        
        <Layout>
            {/* <iframe class='peep' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vThi9LBctNurW1MRjvdkHF8PfblbaBnCCm_Vb6dirUivxT8C7GAmccCrvauBDpELgZH-8RDfUDOSEq8/pubhtml?gid=1450975924&amp;single=true&amp;widget=false&amp;headers=false&amp;chrome=false&amp;range=Embed!A1:S5"></iframe> */}
            {/* {getValue()} */}

            {/* <iframe class='peep' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vThi9LBctNurW1MRjvdkHF8PfblbaBnCCm_Vb6dirUivxT8C7GAmccCrvauBDpELgZH-8RDfUDOSEq8/pubhtml?gid=1450975924&amp;single=true&amp;widget=false&amp;headers=false&amp;chrome=false&amp;range=A1:S5"></iframe> */}
            <table className='tgp_table'>
                <tbody>
                <tr>
                    <th>Players</th>
                    <th>Score</th>
                    <th># of Keys</th>
                    <th>AA</th>
                    <th>AV</th>
                    <th>BH</th>
                    <th>HoI</th>
                    <th>NEL</th>
                    <th>NO</th>
                    <th>RLP</th>
                    <th>ULD</th>
                    <th>C1</th>
                    <th>C2</th>
                    <th>C3</th>
                    <th>C4</th>
                    <th>C5</th>
                    <th>C6</th>
                    <th>C7</th>
                    <th>C8</th>
                    <th>C9</th>

                </tr>
                {/* {csvData.map((x => (console.log(x))))} */}
                {csvData.map((row => (
                    <tr>
                        {/* {console.log(Object.values(row))} */}
                        {toSomething(Object.values(row))}
                        {/* {console.log(y[1])} */}
                        {/* {console.log(score)} */}
                        {Object.values(row).map((data, index) => (
                        <td className={doWork(data, index)}>
                            {data}
                        </td>
                    ))}
                    </tr>
                )))}
                </tbody>
            </table>
                {/* {score[0]}
                ,
                {score[1]}
                ,
                {score[2]}
                ,
                {score[3]}
                ,
                {score[4]}
                ,
                {score[5]} */}

      </Layout>
    );
  }
