import React from "react";
import Navbar from '../../Navbar';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft, faShield } from "@fortawesome/free-solid-svg-icons";
import { faDiscord, faTwitch, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faHelmetBattle, faSwords, faDragon, faBug } from "@fortawesome/pro-duotone-svg-icons"
import { Link } from "react-router-dom";

function Layout({ children }) {
    return (
        <>
        <Navbar />
        <main>
            {children}
        </main>
        <footer>
            <div className="footerLinks">
                <span><FontAwesomeIcon icon={faDiscord} style={{ color: "#2596be", }} /> <a href="https://www.discord.com/invite/ognitl" target="_blank"> Discord </a></span>
                <span><FontAwesomeIcon icon={faYoutube} style={{ color: "#2596be", }} /> <a href="https://www.youtube.com/channel/UCGf9VOaOuPIkUhVEv2zL91A" target="_blank"> YouTube </a></span>
                <span><FontAwesomeIcon icon={faTwitch} style={{ color: "#2596be", }} /> <a href="https://www.twitch.tv/ognitl" target="_blank"> Twitch </a></span>
                <span><FontAwesomeIcon icon={faAlignLeft} style={{ color: "#2596be", }} /> <a href="https://www.warcraftlogs.com/guild/reports-list/693604" target="_blank"> Logs </a></span>
                <span><FontAwesomeIcon icon={faShield} style={{ color: "#2596be", }} /> <a href="https://worldofwarcraft.blizzard.com/en-gb/guild/eu/sylvanas/our-guild-name-is-too-lo" target="_blank"> Armory </a></span>
                <span><FontAwesomeIcon icon={faDragon} style={{"--fa-primary-color": "#2596be", "--fa-secondary-color": "#2596be",}} /> <a href="https://raider.io/guilds/eu/sylvanas/Our%20Guild%20Name%20Is%20Too%20Lo" target="_blank"> Raider.io </a></span>
                <span><FontAwesomeIcon icon={faBug} style={{ color: "#2596be", }} /><a href="https://discord.com/channels/1051658709060816947/1079842349347262465" target="_blank"> Submit a bug </a></span>
            </div>
            <div>
                Our Guild Name Is Too Lo
            </div>
        </footer>
        </>
    );
}
export default Layout;