import React, { Component } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import { library } from '@fortawesome/fontawesome-svg-core'
//eslint-disable-next-line 
import { fa1, fa2, fa3, fa4, fa5, fa6, fa7, faAnglesDown, faCheck, faChevronRight, faMugHot, faXmark, fas } from '@fortawesome/free-solid-svg-icons'
//eslint-disable-next-line 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//eslint-disable-next-line 
import { faTwitter, faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import Layout from './components/Layout';
import RulesLayout from './components/rulesLayout';

import '../css/Rules.css'
import { Link } from 'react-router-dom';

library.add(fas, faTwitter, faFontAwesome)

// Our rules are designed to create a fair and supportive environment where everyone can focus on working together to achieve the team's goals. For example, our rules prohibit name-calling, harassment, and other forms of disruptive behavior.<br/><br/>
// Finally, our rules are designed to maximize our chances of success. They require everyone to follow the raid leader's instructions and play their role to the best of their ability. 
export default class RaidRules extends Component {
    render() {
        return (
            <Layout>
                <RulesLayout/>
                <div className='rulesIntro'>
                    <p>
                        <Link to="/rules" className='links center centerOnPage'>&lt; Back to rules</Link>
                        <br/><br/>
                    </p>  

                    <ul className="fa-ul globalList">
                        <li>
                            <span className="fa-li"><FontAwesomeIcon icon={fa1} style={{color: "#2DBAED", }} /></span>
                            <span>Treat everyone with respect. Absolutely no harassment, witch hunting, sexism, racism or hate speech will be tolerated.</span>
                        </li>
                        <li>
                            <span className="fa-li"><FontAwesomeIcon icon={fa2} style={{color: "#2DBAED", }} /></span>
                            <span>If you see something against the rules or something that makes you feel unsafe, let officers know. We want this server and guild to be a welcoming space!</span>
                        </li>
                        <li>
                            <span className="fa-li"><FontAwesomeIcon icon={fa3} style={{color: "#2DBAED", }} /></span>
                            <span>Keep Drama outside of the discord, or Guild chat. We have a very low tolerance for drama and toxicity. Things happen, we get it. Take it to dms, or talk to an officer. If it gets out of hand, always talk to an officer.</span>
                        </li>
                        <li>
                            <span className="fa-li"><FontAwesomeIcon icon={fa4} style={{color: "#2DBAED", }} /></span>
                            <span><b>Officer / GM</b> Decisions are finalized, if you are declined from a raid for good reason. (Ilvl too low, etc) That's that. Any further conflict refers to rule #3</span>
                        </li>
                        <li>
                            <span className="fa-li"><FontAwesomeIcon icon={fa5} style={{color: "#2DBAED", }} /></span>
                            <span>Please change your Discord server name, to your in-game Main character's name to make sure we know who is who.</span>
                        </li>
                        <li>
                            <span className="fa-li"><FontAwesomeIcon icon={fa6} style={{color: "#2DBAED", }} /></span>
                            <span>No Advertising your sales or communities here. Under the right circumstances you can post your media here, but no advertising.</span>
                        </li>
                        <li>
                            <span className="fa-li"><FontAwesomeIcon icon={fa7} style={{color: "#2DBAED", }} /></span>
                            <span>This is an English guild, therefore please only speak English in guild chat or discord as it may alienate people that do not speak your language.</span>
                        </li>
                    </ul>
                </div>
            </Layout>
        )
    }
}