import React, { Component } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import { library } from '@fortawesome/fontawesome-svg-core'
//eslint-disable-next-line 
import { fa1, fa2, fa3, fa4, fa5, fa6, fas } from '@fortawesome/free-solid-svg-icons'
//eslint-disable-next-line 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//eslint-disable-next-line 
import { faTwitter, faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import Layout from './components/Layout';
import RulesLayout from './components/rulesLayout';

import '../css/Rules.css'
import { Link } from 'react-router-dom';

library.add(fas, faTwitter, faFontAwesome)

// Our rules are designed to create a fair and supportive environment where everyone can focus on working together to achieve the team's goals. For example, our rules prohibit name-calling, harassment, and other forms of disruptive behavior.<br/><br/>
// Finally, our rules are designed to maximize our chances of success. They require everyone to follow the raid leader's instructions and play their role to the best of their ability. 
export default class RaidRules extends Component {
    render() {
        return (
            <Layout>
                <RulesLayout/>
                <div className='rulesIntro'>
                <p>
                        <Link to="/rules" className='links center centerOnPage'>&lt; Back to rules</Link>
                        <br/><br/>
                </p>  
                </div>
                <div className='craftColumns'>
                    <div className='craftCol'>
                        <h2 className='center'>When getting a craft done:</h2>
                        <p className='center'>
                            When requesting crafting from a guildie, make sure that you keep in mind, that while they're doing you a favour by not asking you for a (full) price comission.
                            <br/> We don't want guild members to get stuck paying for eachother's crafts. 
                            Therefore we ask that you follow these simple steps for ensuring that everyone benefits from crafting:
                        </p>
                        <div className='rulesChecklist'>
                            <ul className="fa-ul">
                                <li>
                                    <span className="fa-li"><FontAwesomeIcon icon={fa1} style={{color: "#2dbaed",}} /></span><b>Talk</b> to your crafter beforehand. Inform them of any embellishment or extras you want.
                                </li>
                                <li>
                                    <span className="fa-li"><FontAwesomeIcon icon={fa2} style={{color: "#2dbaed",}} /></span><b>Supply</b> your own <b>Artisans Mettle</b>.
                                </li>
                                <li>
                                    <span className="fa-li"><FontAwesomeIcon icon={fa3} style={{color: "#2dbaed",}} /></span>Ask what materials the crafter needs.
                                </li>
                                <li>
                                    <span className="fa-li"><FontAwesomeIcon icon={fa4} style={{color: "#2dbaed",}} /></span><b>Supply</b> your own <b>Materials</b> or pay for them.
                                </li>
                                <li>
                                    <span className="fa-li"><FontAwesomeIcon icon={fa5} style={{color: "#2dbaed",}} /></span>Place the order once you're both comfortable.
                                </li>
                                <li>
                                    <span className="fa-li"><FontAwesomeIcon icon={fa6} style={{color: "#2dbaed",}} /></span>Retrieve your order from the mail, and thank your crafter.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='craftCol'>
                        <h2 className='center'>When crafting for someone:</h2>
                        <p className='center'>
                            As important it is for the person receiving the item to get a high-quality craft, it's equally as important that we don't waste <b>YOUR</b> gold or time on it. 
                            <br/>
                            Therefore we ask that you follow these simple steps for ensuring that everyone benefits from crafting:
                        </p>
                        <div className='rulesChecklist'>
                            <ul className="fa-ul">
                                <li>
                                    <span className="fa-li"><FontAwesomeIcon icon={fa1} style={{color: "#2dbaed",}} /></span><b>Talk</b> to your client beforehand.
                                </li>
                                <li>
                                    <span className="fa-li"><FontAwesomeIcon icon={fa2} style={{color: "#2dbaed",}} /></span>Let your client know what materials you need from them.
                                </li>
                                <li>
                                    <span className="fa-li"><FontAwesomeIcon icon={fa3} style={{color: "#2dbaed",}} /></span>Let your client know what the quality of the craft will be.
                                </li>
                                <li>
                                    <span className="fa-li"><FontAwesomeIcon icon={fa4} style={{color: "#2dbaed",}} /></span>Complete the order once you're both comfortable.
                                </li>
                                <li>
                                    <span className="fa-li"><FontAwesomeIcon icon={fa5} style={{color: "#2dbaed",}} /></span>Let your client know you're done.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}