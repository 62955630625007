import Layout from "./components/Layout";
import '../css/Logs.css'

const fetch = require('node-fetch');

const url = 'https://www.warcraftlogs.com/guild/reports-list/693604';

fetch(url, {
    mode: 'no-cors'
})
  .then(response => {response.text();})
  .then(html => {
    // const domParser = new DOMParser();
    // const document = domParser.parseFromString(html, 'text/html');

    // const table = document.querySelector('table');
    // const tableText = table.textContent;

    //console.log(document);

    // Do something with the table text here
  });


function Logs(){
    return(
        <Layout>
          <h3>Coming soon!</h3>
        </Layout>
    );
}

export default Logs;