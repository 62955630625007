function DarkThemeBtn() {
    return (
        <button className='themeBtn dark'>
            <div id='night-decoration'>
                <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="13.5" cy="11.5" r="0.5" fill="white" />
                    <circle cx="4.5" cy="18.5" r="0.5" fill="white" />
                    <circle cx="10.5" cy="19.5" r="0.5" fill="white" />
                    <circle cx="18.5" cy="17.5" r="1.5" fill="white" />
                    <circle cx="25.5" cy="16.5" r="0.5" fill="white" />
                    <circle cx="0.5" cy="10.5" r="0.5" fill="white" />
                    <circle cx="6.5" cy="10.5" r="1.5" fill="white" />
                    <circle cx="2.5" cy="3.5" r="0.5" fill="white" />
                    <circle cx="12.5" cy="23.5" r="0.5" fill="white" />
                    <circle cx="11.5" cy="0.5" r="0.5" fill="white" />
                    <circle cx="19.5" cy="11.5" r="0.5" fill="white" />
                    <circle cx="17.5" cy="3.5" r="0.5" fill="white" />
                    <circle cx="30.5" cy="2.5" r="0.5" fill="white" />
                    <circle cx="30.5" cy="8.5" r="0.5" fill="white" />
                    <circle cx="23.5" cy="6.5" r="1.5" fill="white" />
                    <circle cx="11.5" cy="5.5" r="0.5" fill="white" />
                    <circle cx="20.5" cy="23.5" r="0.5" fill="white" />
                </svg>
            </div>
            <div id='moon'>
                <svg width="34" height="36" viewBox="0 0 34 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_i_18_49)">
                        <ellipse cx="16.7783" cy="17.7239" rx="16.7783" ry="17.7239" fill="#EBEBEB" />
                    </g>
                    <g filter="url(#filter1_i_18_49)">
                        <circle cx="16.5" cy="9.5" r="5.5" fill="white" />
                    </g>
                    <g filter="url(#filter2_i_18_49)">
                        <circle cx="7" cy="21" r="4" fill="white" />
                    </g>
                    <g filter="url(#filter3_i_18_49)">
                        <circle cx="21.5" cy="24.5" r="5.5" fill="white" />
                    </g>
                    <defs>
                        <filter id="filter0_i_18_49" x="0" y="0" width="33.5567" height="35.4478" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset />
                            <feGaussianBlur stdDeviation="1" />
                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.75 0" />
                            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_18_49" />
                        </filter>
                        <filter id="filter1_i_18_49" x="11" y="4" width="11" height="11" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset />
                            <feGaussianBlur stdDeviation="2.5" />
                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.75 0" />
                            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_18_49" />
                        </filter>
                        <filter id="filter2_i_18_49" x="3" y="17" width="8" height="8" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset />
                            <feGaussianBlur stdDeviation="1.5" />
                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.75 0" />
                            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_18_49" />
                        </filter>
                        <filter id="filter3_i_18_49" x="16" y="19" width="11" height="11" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset />
                            <feGaussianBlur stdDeviation="1.5" />
                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.75 0" />
                            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_18_49" />
                        </filter>
                    </defs>
                </svg>
            </div>
        </button>
    );
}

export default DarkThemeBtn;