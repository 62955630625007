import React, { Component } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import { library } from '@fortawesome/fontawesome-svg-core'
//eslint-disable-next-line 
import { faAnglesDown, faCheck, faChevronRight, faMugHot, faXmark, fas } from '@fortawesome/free-solid-svg-icons'
//eslint-disable-next-line 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//eslint-disable-next-line 
import { faTwitter, faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import Layout from './components/Layout';
import RulesLayout from './components/rulesLayout';

import '../css/Rules.css'
import { Link } from 'react-router-dom';

library.add(fas, faTwitter, faFontAwesome)

// Our rules are designed to create a fair and supportive environment where everyone can focus on working together to achieve the team's goals. For example, our rules prohibit name-calling, harassment, and other forms of disruptive behavior.<br/><br/>
// Finally, our rules are designed to maximize our chances of success. They require everyone to follow the raid leader's instructions and play their role to the best of their ability. 
export default class RaidRules extends Component {
    render() {
        return (
            <Layout>
                <RulesLayout/>
                <div className='rulesIntro'>
                <p>
                    <Link to="/rules" className='links center centerOnPage'>&lt; Back to rules</Link>
                    <br/><br/>
                </p>  
                </div>
                <div className='rulesIntro'>
                    <p>
                        In order to keep our Guild Roster looking fresh and clean. We have some rules in place that ensure that everyone taking up a slot in our Guild (be it alt or main)
                        is kicked after a certain amount of inactivity.
                        <br/> We've all been in one of those guilds that has 900+ members but only 5 people online at a time! We're not striving to become like them, and instead opt to keep an active playerbase within our guild.
                        <br/><br/> Please do note that none of this is personal. If you lose track of an alt, stop playing them for a few months or your sub runs out and you decide to renew it months later - you are always more than welcome to come back!
                        Just let us know in our discord and we'll happily invite you back.
                    </p>
                </div>
                <div className='bankCol'>
                <table cellPadding="5" cellSpacing="0" width="95%" rules="none">
                    <tr>
                        <td></td>
                        <td>Kicked from Guild</td>
                        <td>Demote from</td>
                        <td>Promotion to</td>
                    </tr>
                    <tr>
                        <td>Raider</td>
                        <td>N/A</td> {/* Kick */}
                        <td>1 month</td> {/* Demote */}
                        <td>Prog Raiders Only</td> {/* Promotion */}
                    </tr>
                    <tr>
                        <td>Core Member</td>
                        <td>4 months</td> {/* Kick */}
                        <td>N/A</td> {/* Demote */}
                        <td>28 days [Level 70]</td> {/* Promotion */}
                    </tr>
                    <tr>
                        <td>Member</td>
                        <td>2 Months</td> {/* Kick */}
                        <td>N/A</td> {/* Demote */}
                        <td>14 days</td> {/* Promotion */}
                    </tr>
                    <tr>
                        <td>New Member</td>
                        <td>2 Months</td> {/* Kick */}
                        <td>N/A</td> {/* Demote */}
                        <td>Default Role</td> {/* Promotion */}
                    </tr>
                </table>
                </div>
            </Layout>
        )
    }
}