import React, { useState, useEffect} from 'react';
import { Button, TextField} from '@mui/material'
import { Buffer } from 'buffer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faTrophy } from '@fortawesome/free-solid-svg-icons';
import Layout from './components/Layout';

import '../css/Classes.css';
import '../css/Roster.css';

export default function GuildRoster() {

    let appId = '41496114f95d471cb5c5720b7bf6a306';
    let appSecret = 'DHaQLMOSONhWcDvcd4L2Kz6OOZ6Qn9Sh';
    
    //eslint-disable-next-line 
    const [loadingToken, setTokenLoading] = useState(true);

    const [loadingRoster, setRosterLoading] = useState(true);
    const [loadingInfo, setInfoLoading] = useState(true);

    //eslint-disable-next-line 
    const [access_token, setAccessToken] = useState('')
    const [_gRoster, setRoster] = useState([])
    const [_gInfo, setInfo] = useState([])

    async function fetchData() {

        let dat_tok;

        setTokenLoading(true);
        //eslint-disable-next-line 
        const token = await fetch('https://us.battle.net/oauth/token', {
            body: new URLSearchParams({ grant_type: 'client_credentials' }),
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'Authorization': 'Basic ' + Buffer.from(`${appId}:${appSecret}`).toString('base64')}
          })
        .then(response => response.json())
        .then(json => {
            setAccessToken(Object.values(json)[0]);
            //console.log(Object.values(json)[0]);
            dat_tok = Object.values(json)[0];
        });
          
        setTokenLoading(false)
        setRosterLoading(true);
        //console.log(typeof token);

        const roster = await fetch('https://eu.api.blizzard.com/data/wow/guild/sylvanas/our-guild-name-is-too-lo/roster?namespace=profile-eu&locale=en_US&access_token=' + dat_tok)
        .then(res => res.json());
        setRoster(roster);
        setRosterLoading(false);
    
        setInfoLoading(true);
        const info = await fetch('https://eu.api.blizzard.com/data/wow/guild/sylvanas/our-guild-name-is-too-lo?namespace=profile-eu&locale=en_US&access_token=' + dat_tok)
        .then(res => res.json());
        setInfo(info);
        setInfoLoading(false);
        
      }
      
      useEffect(() => {
        fetchData();
      }, [setRosterLoading, setInfoLoading, setTokenLoading]);


    
    const _getClass = (param) => {
        switch (param) {
            case 1:
                return 'Warrior'
            case 2:
                return 'Paladin'
            case 3:
                return 'Hunter'
            case 4:
                return 'Rogue'
            case 5:
                return 'Priest'
            case 6:
                return 'Death Knight'
            case 7:
                return 'Shaman'
            case 8:
                return 'Mage'
            case 9:
                return 'Warlock'
            case 10:
                return 'Monk'
            case 11:
                return 'Druid'
            case 12:
                return 'Demon Hunter'
            case 13:
                return 'Evoker'
            default:
                return 'UNDEFINED'
        }
    }

    const _getGuildRank = (param) => {
        switch (param) {
            case 0:
                return 'Guild Master'
            case 1:
                return 'Guild Master'
            case 2:
                return 'Officer'
            case 3:
                return 'Officer (Alt)'
            case 4:
                return 'Raider'
            case 5:
                return 'Core Member'
            case 6:
                return 'Member'
            case 7:
                return 'New Member'
            default:
                return 'UNDEFINED'
        }
    }

    const _getClassColor = (param) => {
        let v = ''
        switch (param) {
            case 1:
                v = 'Warrior';
                break;
            case 2:
                v = 'Paladin';
                break;
            case 3:
                v = 'Hunter';
                break;
            case 4:
                v = 'Rogue';
                break;
            case 5:
                v = 'Priest';
                break;
            case 6:
                v = 'DeathKnight';
                break;
            case 7:
                v = 'Shaman';
                break;
            case 8:
                v = 'Mage';
                break;
            case 9:
                v = 'Warlock';
                break;
            case 10:
                v = 'Monk';
                break;
            case 11:
                v = 'Druid';
                break;
            case 12:
                v = 'DemonHunter';
                break;
            case 13:
                v = 'Evoker';
                break;
            default:
                v = 'UNDEFINED'
                break;
        }
        return 'wowClass ' + v
    }

    function searchPlayer(filter) {
        var nodes = document.getElementsByClassName('wowClass');
      
        let i;
        for (i = 0; i < nodes.length; i++) {
          if (nodes[i].innerText.toLowerCase().includes(filter.toLowerCase())) {
            nodes[i].style.display = "";
          } else {
            nodes[i].style.display = "none";
          }
        }
      }

    return (
        <Layout>
            <div>
            <h1>OGNITL Guild Roster</h1>
                <div className="info">
                    <p className="memberTitle"><FontAwesomeIcon icon={faUsers} style={{ color: "#2596be", }} /> Total Member Count: {_gInfo.member_count}</p>
                    <p className="achieveTitle"><FontAwesomeIcon icon={faTrophy} style={{ color: "#2596be", }} /> Achievement Points: {_gInfo.achievement_points}</p>
                </div>

                <div className="rosterSearch">
                    <TextField
                        id="outlined-basic"
                        variant="filled"
                        fullWidth
                        label="Search for Character"
                        InputLabelProps={{ style: {
                            color:'rgb(255, 255, 255)',
                            fontFamily: 'Poppins'
                        }}}
                        onKeyDown={(e) => {
                            if(e.key === 'Enter')
                            {
                                searchPlayer(e.target.value);
                            }
                            }}
                            inputProps={{ style: {color:'rgb(255, 255, 255)'}}}
                        style={{
                            backgroundColor: 'rgba(255, 255, 255, 0.05)',
                            borderRadius: '10px',
                            borderBottom: '0px',
                        }}
                    />
                </div>
                <br/>
                {loadingRoster && loadingInfo ? <p>Loading!</p> :
                    <div className="roster">
                        {Object.values(_gRoster.members).map(roster =>
                            <div onClick={() => window.open("https://raider.io/characters/eu/" + roster.character.realm.slug + '/' + roster.character.name)}
                            className={_getClassColor(roster.character.playable_class.id)}>
                                <p className="charName"> {roster.character.name} - {roster.character.level}</p>
                                <p className="charRank"> &lt;{_getGuildRank(roster.rank)}&gt; </p>
                                <img className="charImg"src={'https://render.worldofwarcraft.com/eu/character/' + roster.character.realm.slug + '/' + roster.character.id % 256 + '/' + roster.character.id + '-main-raw.png?alt='}></img>
                            </div>)
                        }
                    </div>}
            </div>
        </Layout>
    )
}