import RaidRules from './pages/_RaidRules.js';
import Navbar from './Navbar.js';
import _AboutUs from './pages/_AboutUs.js';
import Router from './Router.js';
import './css/App.css';



function App() {

  return (
    <Router/>
  );
}

export default App;
