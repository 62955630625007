import React, { Component, useRef } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';



import { library } from '@fortawesome/fontawesome-svg-core'
//eslint-disable-next-line 
import { faAnglesDown, faCheck, faChevronRight, faMugHot, faXmark, fas } from '@fortawesome/free-solid-svg-icons'
//eslint-disable-next-line 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//eslint-disable-next-line 
import { faTwitter, faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import Layout from './components/Layout';
import RulesLayout from './components/rulesLayout';

import '../css/Rules.css'

library.add(fas, faTwitter, faFontAwesome)



// Our rules are designed to create a fair and supportive environment where everyone can focus on working together to achieve the team's goals. For example, our rules prohibit name-calling, harassment, and other forms of disruptive behavior.<br/><br/>
// Finally, our rules are designed to maximize our chances of success. They require everyone to follow the raid leader's instructions and play their role to the best of their ability. 
export default function Rules() {



        return (
            <Layout>
                <RulesLayout/>
                <div className="accordionRoot ">
                    <h2>Frequently Asked Questions</h2>
                    
                    <div className="accordionContainer">
                    <Accordion
                        style={{
                            backgroundColor: 'rgba(255, 255, 255, 0.05)',
                            color: 'rgba(255, 255, 255, 1)',
                        }}>
                        <AccordionSummary
                            expandIcon={<FontAwesomeIcon icon={faAnglesDown} />}
                            aria-controls="panel3a-content"
                            id="panel3a-header">
                            <Typography className="accordionTitle"><h3>What happens if I break the rules?</h3></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className="accordionSection">
                                Our guild uses a three-strike system to handle disciplinary issues because it is fair, consistent, and transparent. It gives members multiple chances to correct their behavior before facing serious consequences.
                                The three-strike system works as follows:
                                <ul className="fa-ul">
                                    <li>
                                        <span className="fa-li"><FontAwesomeIcon icon={faChevronRight} style={{color: "#2DBAED", }} /></span>
                                        <span>For a first offense, the member will receive a warning from the guild leadership.</span>
                                    </li>
                                    <li>
                                        <span className="fa-li"><FontAwesomeIcon icon={faChevronRight} style={{color: "#2DBAED", }} /></span>
                                        <span>For a second offense, the member will receive a suspension from the guild discord for a period of time if applicable.</span>
                                    </li>
                                    <li>
                                        <span className="fa-li"><FontAwesomeIcon icon={faChevronRight} style={{color: "#2DBAED", }} /></span>
                                        <span>For a third offense, the member will be removed from the guild.</span>
                                    </li>
                                </ul>
                                Please note that we can and will hand out multiple warnings in rapid succession if the offender fails to change their behaviour as per request of the officer / gm.
                                <br/><br/>
                                All Officers, Trial Officers and Guild Masters have and retain the right to kick or ban members from the guild & guild discord at any time.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        style={{
                            backgroundColor: 'rgba(255, 255, 255, 0.05)',
                            color: 'rgba(255, 255, 255, 1)',
                        }}>
                        <AccordionSummary
                            expandIcon={<FontAwesomeIcon icon={faAnglesDown} />}
                            aria-controls="panel3a-content"
                            id="panel3a-header">
                            <Typography className="accordionTitle"><h3>Who wrote these rules?</h3></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className="accordionSection">
                            We wrote these rules according to what we have experienced in previous guilds, experienced in our own guild, and what we think is a way that people should normally interact with eachother in a WoW guild.
                            <br/><br/>
                            Not everyone may feel the same way about our rules, and everything is always open to discussion. We consider ourselves fairly lenient with these rules if we know that you don't mean harm, but we tend to be a bit stricter on people that are new to our community, because despite the fact that we want to wish everyone welcome to our little community. We also want to make sure that everyone remains comfortable and feels safe.
                            <br/><br/>
                            All in all, the rules were written, and checked by our Officers and Guild Masters back when the guild was originally created.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    </div>
                </div>
            </Layout>
        )
    }