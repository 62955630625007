import React, { Component, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Layout({ children }) {
  const bankRef = useRef(null);
  const globalRef = useRef(null);
  const craftingRef = useRef(null);
  const activityRef = useRef(null);

  useEffect(() => {
    if ( window.location.pathname === '/rules/global' && globalRef.current) 
    {
      globalRef.current.style.animationDuration = '0.5s';
      globalRef.current.style.fontWeight = '700';
    }
    else if ( window.location.pathname === '/rules/bank' && bankRef.current) 
    {
      bankRef.current.style.animationDuration = '0.5s';
      bankRef.current.style.fontWeight = '700';
    }
    else if ( window.location.pathname === '/rules/crafting' && craftingRef.current) 
    {
      craftingRef.current.style.animationDuration = '0.5s';
      craftingRef.current.style.fontWeight = '700';
    }
    else if ( window.location.pathname === '/rules/activity' && activityRef.current) 
    {
      activityRef.current.style.animationDuration = '0.5s';
      activityRef.current.style.fontWeight = '700';
    }
  }, []);

    return (
          <div>
            <h1>OGNITL Rules</h1>
            <div className='rulesIntro bottomBorder'>
                <p>
                  Having rules in a World of Warcraft guild is important for a number of reasons. 
                <br/><br/>
                  First, it helps to ensure that everyone is on the same page and that there is a clear understanding of what is expected of them. This can help to reduce conflict and drama, and create a more positive and productive environment for everyone.
                <br/>
                  Second, rules can help to protect all members of the guild from harassment and discrimination. By having rules in place that prohibit harmful behavior, guilds can create a safe and welcoming environment for everyone.
                <br/>
                  Finally, rules can help to build a sense of community within the guild. When everyone is following the same rules and working towards the same goals, it can help to create a sense of camaraderie and belonging.
                <br/>
                  Thank you for taking the time to read these rules. If you have any further questions you can always contact us for support on discord.
                </p>
                <p className="rulesSignature ">
                  - OGNITL GM & Officer Team
                </p>
            </div>
            <div className='rulesIntro bottomBorder rulesLinks'>
              <Link to="/rules/global" className='links' ref={globalRef}>Global Rules</Link>
              <Link to="/rules/bank" className='links' ref={bankRef}>Bank Rules</Link>
              <Link to="/rules/crafting" className='links' ref={craftingRef}>Crafting Rules</Link>
              <Link to="/rules/activity" className='links' ref={activityRef}>Activity Rules</Link>
            </div>
          </div>
    );
}
export default Layout;