import React, { Component } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import { library } from '@fortawesome/fontawesome-svg-core'
//eslint-disable-next-line 
import { faAnglesDown, faCheck, faChevronRight, faMugHot, faXmark, fas } from '@fortawesome/free-solid-svg-icons'
//eslint-disable-next-line 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//eslint-disable-next-line 
import { faTwitter, faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import Layout from './components/Layout';
import RulesLayout from './components/rulesLayout';

import '../css/Rules.css'
import { Link } from 'react-router-dom';

library.add(fas, faTwitter, faFontAwesome)

// Our rules are designed to create a fair and supportive environment where everyone can focus on working together to achieve the team's goals. For example, our rules prohibit name-calling, harassment, and other forms of disruptive behavior.<br/><br/>
// Finally, our rules are designed to maximize our chances of success. They require everyone to follow the raid leader's instructions and play their role to the best of their ability. 
export default class RaidRules extends Component {
    render() {
        return (
            <Layout>
                <RulesLayout/>
                <div className='rulesIntro'>
                <p>
                        <Link to="/rules" className='links center centerOnPage'>&lt; Back to rules</Link>
                        <br/><br/>
                </p>  
                </div>
                <div className='rulesIntro'>
                    <p>
                        It's useful to know what you can and cannot do in our guild bank, you may use this handy sheet underneath to check how many stacks you can collect, and repairs you can have done per day!
                        <br/>
                        We ask that you try to help us stock-up where and when you can. We don't ask for much! 
                        <br/>
                        But we give quite a bit. If you could help out by leaving some materials, or a little bit of gold every now and again, that helps a lot!
                    </p>
                </div>    
                <div className='bankCol'>
                <table cellpadding="5" cellspacing="0" width="95%" rules="none">
                    <tr>
                        <td></td>
                        <td>Gold / Day</td>
                        <td>Enhancements</td>
                        <td>DF Mats 1</td>
                        <td>DF Mats 2</td>
                        <td>Old Mats</td>
                        <td>Runes and Flasks</td>
                        <td>Potions</td>
                        <td>Misc</td>
                    </tr>
                    <tr>
                        <td>Officer</td>
                        <td>500</td> {/* Gold Per Day */}
                        <td>50</td> {/* Enhancements */}
                        <td>50</td> {/* DF Mats 1 */}
                        <td>50</td> {/* DF Mats 2 */}
                        <td>50</td> {/* Old Mats */}
                        <td>50</td> {/* Runes and Flasks */}
                        <td>50</td> {/* Potions */}
                        <td>50</td> {/* Misc */}
                    </tr>
                    <tr>
                        <td>Raider</td>
                        <td>350</td> {/* Gold Per Day */}
                        <td>1</td> {/* Enhancements */}
                        <td>1</td> {/* DF Mats 1 */}
                        <td>1</td> {/* DF Mats 2 */}
                        <td>3</td> {/* Old Mats */}
                        <td>1</td> {/* Runes and Flasks */}
                        <td>1</td> {/* Potions */}
                        <td>1</td> {/* Misc */}
                    </tr>
                    <tr>
                        <td>Core Member</td>
                        <td>250</td> {/* Gold Per Day */}
                        <td>0</td> {/* Enhancements */}
                        <td>0</td> {/* DF Mats 1 */}
                        <td>0</td> {/* DF Mats 2 */}
                        <td>3</td> {/* Old Mats */}
                        <td>0</td> {/* Runes and Flasks */}
                        <td>1</td> {/* Potions */}
                        <td>0</td> {/* Misc */}
                    </tr>
                    <tr>
                        <td>Member</td>
                        <td>200</td> {/* Gold Per Day */}
                        <td>0</td> {/* Enhancements */}
                        <td>0</td> {/* DF Mats 1 */}
                        <td>0</td> {/* DF Mats 2 */}
                        <td>3</td> {/* Old Mats */}
                        <td>0</td> {/* Runes and Flasks */}
                        <td>0</td> {/* Potions */}
                        <td>0</td> {/* Misc */}
                    </tr>
                    <tr>
                        <td>New Member</td>
                        <td>50 (Repairs Only)</td> {/* Gold Per Day */}
                        <td>0</td> {/* Enhancements */}
                        <td>0</td> {/* DF Mats 1 */}
                        <td>0</td> {/* DF Mats 2 */}
                        <td>3</td> {/* Old Mats */}
                        <td>0</td> {/* Runes and Flasks */}
                        <td>0</td> {/* Potions */}
                        <td>0</td> {/* Misc */}
                    </tr>
                </table>
                </div>
            </Layout>
        )
    }
}